import './HomePage.css'

function HomePage(props) {
    return (
        <div className="home">
            <div className="titleWrapper">
                <h1>¡Hola! My name is Paulina Cabrera,</h1>
                <h2>and I’m a project manager that seeks better ways to <br /> help people with the power of knowledge.</h2>
            </div>

            <p>
                Currently based in Austin and working full-time at <a href="https://solarwinds.com/">SolarWinds</a> as a Creative Operations Specialist. I’m always happy to discuss new partnerships and opportunities. Contact me on <a href="https://www.linkedin.com/in/paulinacabrera/">LinkedIn</a>.
            </p>
        </div>
    );
}

export default HomePage;