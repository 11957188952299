import './Nav.css';

function Nav(props) {
    return (
        <div className="nav">
            {/* <h3 onClick={props.homepageClick}>Home</h3> */}
            {/* <h3 onClick={props.skillsClick}>Skills</h3>
            <a href="https://github.com/MarkBerube">Projects</a>
            <a href="./resume.pdf">Resume</a> */}
        </div>
    );
}

export default Nav;