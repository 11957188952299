import React from 'react';
import HomePage from '../HomePage/HomePage';
import Nav from '../Nav/Nav';
import "./PageViewer.css"

class PageViewer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {date: new Date(), page: "homepage"};
      this.handleHomePageClick = this.handleHomePageClick.bind(this)
    //   this.handleSkillsPageClick = this.handleSkillsPageClick.bind(this)
    }

    handleHomePageClick() {
        this.setState({page: "homepage"});
    }

    // handleSkillsPageClick() {
    //     this.setState({page: "skills"});
    // }
  
    render() {
        let page;
        if (this.state.page === 'homepage') {
            page = <HomePage />
        }
        
        return (
            <div className="pageViewer">
                <div className="page">
                    {page}
                </div>
                <Nav homepageClick={this.handleHomePageClick} skillsClick={this.handleSkillsPageClick} />
            </div>
      );
    }
}

export default PageViewer;